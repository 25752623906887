export const navItems = [
  {
    name: "About",
    link: "#about",
  },
  {
    name: "Projects",
    link: "#project1",
  },
  {
    name: "Contact",
    link: "#contact",
  },
];
